:root {
  --fw-padding: 0 88px;
  --fw-padding-xy: 0 88px 48px 168px;
  --fw-padding-xy-mobile: 180px 50px 24px;
  --fw-padding-xy-mobile-lg: 0 50px 24px 50px;

  --color-black: #000;
  --color-white: #ffff;
  --color-background: #f9f5e7;
  --color-accent: #5984e4;
  --color-lighterAccent: #aac2ef;

  --font-main: 'Montserrat', sans-serif;
  --font-secondary: 'Inconsolata', monospace;
}

*,
body,
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
/*   margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
 /*  -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .button-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  }
}

button {
  background-color: transparent;
  color: var(--color-accent);
  border-style: none;
  font-family: var(--font-main);
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
  cursor: pointer;
  margin: 10px 10px 20px;
  /* padding: 1vh 2vw; */
}

@media (max-width: 767.98px) {
  button {
    margin: 20px 10px 0;
  }
}

button:hover {
  color: var(--color-lighterAccent);
}

.slide img {
  width: 100%;
}

.slider-control-centerright button, .slider-control-centerleft button {
  font-size: 12px;
  margin: 0 10px;
}